<template>
  <div>
    <div
      class="modal bg-white fade"
      tabindex="-1"
      aria-hidden="true"
      :id="modalCropperId"
      ref="modalCropper"
    >
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-fullscreen">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header" id="__modalConfig.id___header">
            <!--begin::Modal title-->
            <h2 class="fw-bolder">Crop and upload</h2>
            <div
              data-bs-dismiss="modal"
              class="btn btn-icon btn-sm btn-active-icon-primary"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
              </span>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body py-10 px-lg-10">
            <div class="row g-9 mb-7">
              <div class="col-md-3 fv-row">
                <input
                  :id="inputSelectFileId"
                  type="file"
                  name="image"
                  accept="image/*"
                  @change="setImage"
                />
                <br />
                <!-- Image previewer -->
                <!-- <img :src="imageSrc" width="200" /> -->
                <br />

                <button
                  type="button"
                  v-if="this.croppedImageSrc"
                  @click="uploadImage"
                >
                  Upload
                </button>

                <div
                  v-if="this.imageSrc"
                  class="
                    my-3
                    d-flex
                    align-items-center
                    justify-content-center
                    mx-auto
                  "
                ></div>

                <div
                  class="
                    alert alert-primary
                    d-flex
                    align-items-center
                    p-5
                    mb-10
                  "
                >
                  <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
                    <inline-svg src="media/icons/duotune/general/gen048.svg" />
                  </span>
                  <div class="d-flex flex-column">
                    <h4 class="mb-1 text-primary">Hướng dẫn</h4>
                    <span
                      >- Di chuyển đến góc để điều chỉnh kích thước ô
                      vuông</span
                    >
                    <span>- Kéo ô vuông vào vị trí muốn cắt, bấm OK</span>
                  </div>
                </div>
              </div>
              <div class="col-md-6 fv-row">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <vue-cropper
                    class="mr-2 w-50"
                    ref="cropper"
                    :guides="true"
                    :src="imageSrc"
                    :minCropBoxWidth="
                      cropperConfigs[typeImage]['minCropBoxWidth']
                    "
                    :minCropBoxHeight="
                      cropperConfigs[typeImage]['minCropBoxHeight']
                    "
                    :minContainerHeight="
                      cropperConfigs[typeImage]['minContainerHeight']
                    "
                    :minContainerWidth="
                      cropperConfigs[typeImage]['minContainerWidth']
                    "
                    :initialAspectRatio="
                      cropperConfigs[typeImage]['initialAspectRatio']
                    "
                    :aspectRatio="cropperConfigs[typeImage]['aspectRatio']"
                    viewMode="2"
                    highlight="true"
                    checkOrientation="true"
                    movable="false"
                    scalable="false"
                    zoomable="false"
                    zoomOnWheel="false"
                    background="false"
                    dragMode="move"
                  ></vue-cropper>
                </div>
              </div>
            </div>
          </div>

          <!-- Cropper container -->

          <!--begin::Modal footer-->
          <div class="modal-footer flex-center">
            <!--begin::Button-->

            <button
              :data-kt-indicator="uploading ? 'on' : null"
              class="btn btn-lg btn-primary"
              type="button"
              @click="uploadImage()"
            >
              <span v-if="!uploading" class="indicator-label">
                {{ $t("common.submit") }}
                <span class="svg-icon svg-icon-3 ms-2 me-0">
                  <inline-svg src="icons/duotune/arrows/arr064.svg" />
                </span>
              </span>
              <span v-if="uploading" class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <!--end::Button-->
          </div>
          <!--end::Modal footer-->
        </div>
      </div>
    </div>

    <div v-show="false">
      <button
        class="btn"
        type="button"
        :id="btnShowModalId"
        data-bs-toggle="modal"
        :data-bs-target="`#${modalCropperId}`"
      >
        Show modal
      </button>
    </div>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { ref } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import { hideModal } from "@/core/helpers/dom";
import { generateRandomString } from "@/core/helpers/documentation";

export default {
  components: {
    VueCropper,
  },
  props: {
    modalCropperId: String,
    type: String,
  },
  data: function () {
    return {
      imageSrc: "",
    };
  },
  methods: {
    selectFile: function () {
      console.log(this.inputSelectFileId);
      document.getElementById(this.inputSelectFileId).click();
    },
    setImage: function (e) {
      const file = e.target.files[0];
      if (!file.type.includes("image/")) {
        alert("Vui lòng chọn đúng định dạng ảnh: *.jpg, *.jpeg, *.png, *.bmp");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imageSrc = event.target.result;

          // Rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);

        // show modal
        document.getElementById(this.btnShowModalId).click();
      } else {
        alert(
          "Error, Trình duyệt không support FileReader API. Vui lòng sử dụng trình duyệt Chrome, Cốc Cốc hoặc FireFox"
        );
      }
    },
  },

  setup(props, { emit }) {
    const typeImage = props.type || "avatar";

    const cropperConfigs = {
      cover: {
        minCropBoxWidth: 500,
        minCropBoxHeight: (500 * 4) / 7,
        minContainerHeight: 650,
        minContainerWidth: 650,
        initialAspectRatio: 7 / 4,
        aspectRatio: 7 / 4,
      },
      avatar: {
        minCropBoxWidth: 50,
        minCropBoxHeight: 50,
        minContainerHeight: 650,
        minContainerWidth: 650,
        initialAspectRatio: 1,
        aspectRatio: 1,
      },
    };

    const store = useStore();
    const modalCropper = (ref < null) | (HTMLButtonElement > null);
    const cropper = ref(null);
    const inputSelectFileId =
      "input-select-file-cropper-id-" + generateRandomString(6);
    let uploading = ref(false);
    const getTokenUpload = async () => {
      return await store
        .dispatch("getTokenUpload", {
          class: typeImage,
          type: "image",
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const btnShowModalId = "button-show-modal-id-" + generateRandomString(6);

    const configUpload = {
      avatar: {
        url: "https://upload-vm.daybreak.icu/up_av",
      },
      cover: {
        url: "https://upload-vm.daybreak.icu/up_cov",
      },
    };
    const uploadImage = async () => {
      uploading = true;
      const config = await getTokenUpload();
      console.log(cropper);

      const crop_data = cropper.value.getData(true);
      console.log(crop_data);
      var formData = new FormData();
      formData.append("name", config.name);
      formData.append("exp", config.exp);
      formData.append("class", config.class);
      formData.append("hmac", config.hmac);
      formData.append("extra", config.extra);
      formData.append("crop_data", JSON.stringify(crop_data));
      formData.append(
        "image",
        document.getElementById(inputSelectFileId).files[0]
      );

      axios
        .post(configUpload[typeImage].url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.status === "success") {
            hideModal(modalCropper.value);
            emit("uploadSuccess", response.data.url);
            document.getElementById(btnShowModalId).click();
            uploading = false;
          } else {
            emit("uploadFail", response.data);
            uploading = false;
          }
          reset();
        });
    };

    const reset = () => {
      uploading = false;
      document.getElementById(inputSelectFileId).value = "";
    };

    return {
      uploading,
      btnShowModalId,
      configUpload,
      inputSelectFileId,
      getTokenUpload,
      uploadImage,
      modalCropper,
      cropper,
      cropperConfigs,
      typeImage,
      reset,
    };
  },
};
</script>
